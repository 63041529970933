import { XMLParser } from 'fast-xml-parser';

type TranslationStrings = {
  [key: string]: string
}

class Translator {
  constructor() {
  }

  public translate(xliffSource: string, usesource: boolean = false ): TranslationStrings {
    const parser = new XMLParser({
      "attributeNamePrefix": '_',
      "ignoreAttributes": false
    });
    var allStrings: TranslationStrings = {};
    const xliffJson = parser.parse( xliffSource )
    var data: any = xliffJson.xliff.file.body["trans-unit"];

    if (usesource) {
      xliffJson.xliff.file.body["trans-unit"].forEach( (unit: { _resname: string | number; source: any; }) => {
        allStrings[unit._resname] = unit.source
      })
    } else {
      xliffJson.xliff.file.body["trans-unit"].forEach( (unit: { _resname: string | number; target: any; }) => {
        allStrings[unit._resname] = unit.target
      })
    }

    return allStrings;
  }
}

export { Translator };
export { TranslationStrings };

