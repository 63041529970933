const gtag: Gtag.Gtag = function (): void { (<any>window).dataLayer.push(arguments) };
(<any>window).dataLayer = []

gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'functionality_storage': 'denied',
    'personalization_storage': 'denied',
    'security_storage': 'denied',
});

class GoogleTagManager {

    public GTMID: string
    public MeasurementID: string
    public host: string

    constructor(GTMID: string = 'GTM-NN5D7RC', MeasurementID: string = 'G-RLDYB21GJW', host: string = 'tracking.xrow.de') {
        if (host) {
            this.host = host
        }
        if (GTMID) {
            this.GTMID = GTMID
        }
        if (MeasurementID) {
            this.MeasurementID = MeasurementID
        }
        window.gtag = gtag
        this.init()
    }

    public init(): void {

        gtag('js', new Date());

        gtag('config', this.MeasurementID, {
            'transport_url': 'https://' + this.host,
            'first_party_collection': true
        });

        gtag('config', this.GTMID, { 'allow_enhanced_conversions': true });

        (function (w: any, d: any, s: string, l: any, i: string, h: string ) {
            w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''
            j.async = true
            j.src = 'https://' + h + '/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f)
        })(window, document, 'script', 'dataLayer', this.GTMID, this.host);

        console.log('Server Side Tracking enabled for https://' + this.host)
    }

    public addCallToAction( classname: string, eventname: string, text?: string ): void {
        if (!text){
          var text = eventname + " triggered"
        }
        const elementsArray = document.getElementsByClassName(classname);

        [...elementsArray].forEach((element: any , index: number, array: Array<any> ) => {
          element.addEventListener("click", function (event: Event) {
            console.log( text )
            window.gtag('event', eventname, {'click_text': this.innerText.trim()})
          })
        })
    }

    public callToAction( element: any, eventname: string, text?: string ): void {
        if (!text) {
          var text = eventname + " triggered"
        }
        console.log( text )
        window.gtag('event', eventname, {'click_text': element.innerText.trim()});
    }

    public eventWithData(eventname: string, eventParams: any, text?: string): void {
        console.log(!text ? eventname + ' triggered' : text)
        window.gtag('event', eventname, eventParams)
    }

    public static consentDenied(): void {
        gtag('consent', 'update', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
        });
    }
    public static consentGranted(): void {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            'functionality_storage': 'granted',
            'personalization_storage': 'granted',
            'security_storage': 'granted',
        });
    }
}

export { GoogleTagManager, gtag }
