export class XrowStepButton {
    private button: HTMLButtonElement

    constructor(form: HTMLFormElement, step: string, buttonlabel: string) {
        if (!form.querySelector('.' + step + ' button')) {
            const stepContainer = form.querySelector('.' + step)
            if (!stepContainer) {
                throw `Cannot find '${step}'. Cannot add button to step.`
            }

            stepContainer.insertAdjacentHTML(
                'afterend',
                `<div class="col-12 ${step}">
                    <div class="row s_col_no_resize s_col_no_bgcolor">
                        <div class="col-sm-auto">
                            <button type="button" class="btn btn-primary btn-lg">${buttonlabel}</button>
                        </div>
                    </div>
                </div>`
            )
        }

        this.button = form.querySelector('.' + step + ' button')
    }

    public get(): HTMLButtonElement {
        return this.button
    }

    public disable(): void {
        this.button.disabled = true
    }

    public enable(): void {
        this.button.disabled = false
    }
}
