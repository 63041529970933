/**
 * We use Javascript to rearrange the DOM and add classes to various components.
 *
 * We could of course edit the HTML directly in Odoo but this is easier while
 * developing because the developer can try out changes locally without them being
 * seen in the production system. It's also a way to ensure consistency in parts
 * that should be identical but (due to human error while editing) are not.
 *
 * Once the design is stable, you might want to edit the HTML directly in Odoo and
 * remove this Javascript.
 */
export class XrowStyling {
    public static run(): void {
        const banners = document.querySelectorAll('section[data-snippet="s_banner"].o_background_video')
        banners.forEach((banner: HTMLElement) => {this.styleVideoBanner(banner)})

        const featuresGrids = document.querySelectorAll('section[data-snippet="s_features_grid"]')
        featuresGrids.forEach((grid: HTMLElement) => {this.styleFeaturesGrid(grid)})

        const carousels = document.querySelectorAll('section[data-snippet="s_carousel"]')
        carousels.forEach((carousel: HTMLElement) => {this.styleCarousel(carousel)})

        this.handleSlideClicks()
    }

    /**
     * Let users click or tap a slide to go on to the next one in the carousel.
     */
    private static handleSlideClicks() {
        document.addEventListener('click', function (event) {
            const slide = (<HTMLElement> event.target).closest('.carousel-item')
            if (!slide) {
                return
            }

            event.preventDefault()

            const element = slide.closest('.carousel')
            if (!element) {
                console.error('Found orphan carousel item')
                return
            }

            const carousel = (window as any).Carousel.getInstance(element)
            if (!carousel) {
                console.error('Carousel is not initialised')
                return
            }

            carousel.next()
        }, false)
    }

    /**
     * Add the Silver Partner logo to a banner.
     */
    private static styleVideoBanner(banner: HTMLElement) {
        const logoContainer = document.createElement('div')
        logoContainer.classList.add('col-lg-2', 'mt-5', 'mt-lg-0', 'd-flex', 'justify-content-end')

        const logo = document.createElement('img')
        logo.src = '/web/image/59327/xrow-ibexa-silver-partner.png'
        logo.classList.add('img-fluid', 'mx-auto', 'mt-5', 'mt-auto', 'w-25', 'w-lg-100', 'banner-ibexa-logo')
        logoContainer.appendChild(logo)

        const row = banner.querySelector('.s_nb_column_fixed')
        if (row === null) {
            console.error("Unexpected DOM structure found in banner component, not adding logo.")
            return
        }

        row.appendChild(logoContainer)
    }

    /**
     * Style a carousel component according to its name.
     */
    private static styleCarousel(carousel: HTMLElement) {
        this.onlyOneActive(carousel)

        if (carousel.dataset.name == 'Block MArtin') {
            this.styleCarouselBlockMartin(carousel)
            return
        }

        carousel.classList.add('mb64')
        carousel.querySelectorAll('.carousel-content').forEach((item: HTMLElement) => {this.styleCarouselItem(item)})

        this.styleCarouselIndicators(carousel)
    }

    /**
     * Ensure that only one item in a carousel is active.
     */
    private static onlyOneActive(carousel: HTMLElement) {
        let actives = 0;
        carousel.querySelectorAll('.carousel-item').forEach((item: HTMLElement) => {
            if (item.classList.contains('active')) {
                if (++actives > 1) {
                    item.classList.remove('active')
                }
            }
        })
    }

    /**
     * Add a class to the indicators in a carousel.
     */
    private static styleCarouselIndicators(carousel: HTMLElement) {
        carousel.querySelector('.carousel-indicators').classList.add('carousel-indicators-outside')
    }

    /*
     * Default styling for carousel slide with dark blue background.
     */
    private static styleCarouselItem(textCol: HTMLElement) {
        textCol.parentElement.classList.add('o_cc3', 'rounded-4', 'px-5', 'pt64', 'pb64', 'align-items-center')
        textCol.classList.remove('o_cc1', 'pb0', 'pt16', 'col-lg-10', 'col-lg-11', 'col-lg-12', 'offset-lg-1')
        textCol.classList.add('col-lg-7', 'pt48', 'pb48', 'px-0', 'px-sm-5', 'flex-grow-1', 'order-2', 'order-lg-1')
        textCol.querySelectorAll('.text-black').forEach((element: HTMLElement) => {
            element.classList.replace('text-black', 'text-white')
        })

        textCol.closest('.carousel-item').classList.remove('o_cc2', 'pt0')

        const image = textCol.querySelector('img')
        const imageParent = image.parentElement
        image.classList.remove('float-end', 'o_we_custom_image')
        image.classList.add('bg-white', 'rounded-4', 'px-5', 'py-5')
        image.style.width = null

        // Create a new column and move the image and stars into it
        const imageCol = document.createElement('div')
        imageCol.classList.add('col-lg-5', 'pt48', 'pb48', 'pe-lg-5', 'carousel-image-usecase', 'flex-shrink-1', 'order-1', 'order-lg-2')
        textCol.after(imageCol)
        imageCol.appendChild(image)

        if (imageParent.textContent.trim().length == 0) {
            imageParent.remove()
        }

        const stars = textCol.querySelector('.o_stars')

        if (stars === null) {
            console.warn("Did not find child with class 'o_stars'")
            return
        }

        imageCol.appendChild(stars)
    }

    /*
     * Modify the DOM and CSS classes of the testimonials carousel at https://www.xrow.de/nyce-sn-de-de-ibexa
     * (this carousel has a light background).
     */
    private static styleCarouselBlockMartin(carousel: HTMLElement) {
        carousel.classList.add('mx-auto', 'mb64', 'container')
        carousel.querySelector('.carousel-inner').classList.add('rounded-4')
        carousel.querySelectorAll('.carousel-content').forEach((item: HTMLElement) => {this.styleCarouselBlockMartinItem(item)})
        this.styleCarouselIndicators(carousel)
    }

    private static styleCarouselBlockMartinItem(carouselItem: HTMLElement) {
        carouselItem.classList.remove('o_cc1','pt16')
        carouselItem.classList.replace('col-lg-12', 'col-lg-8')
        carouselItem.parentElement.classList.add('mt48', 'mb48', 'mx-2', 'mx-md-4', 'mx-lg-64')

        const image = carouselItem.querySelector('img')
        image.classList.remove('float-end')
        image.classList.add('rounded-4')
        image.style.width = null

        // Create a new column and move the image into it
        const imageCol = document.createElement('div')
        imageCol.classList.add('col-lg-4', 'carousel-image-testimonial')
        carouselItem.before(imageCol)
        imageCol.appendChild(image)
    }

    private static styleFeaturesGrid(grid: HTMLElement) {
        grid.classList.remove('pt48', 'pb24')
        grid.classList.add('pt0', 'pb0')

        const columns = grid.querySelectorAll('.col-lg-6')
        columns.forEach((column: HTMLElement) => {
            column.classList.remove('pb24')
            column.classList.add('my-3', 'my-lg-0')

            const inner = <HTMLElement>column.querySelector('.col-lg-12')
            inner.classList.add('mx-auto', 'col-10')
            if (!inner.classList.contains('border')) {
                return
            }

            const row = <HTMLElement>inner.parentElement
            row.classList.add('h-100', 'bg-transparent')

            inner.classList.remove('col-lg-12', 'border', 'pt8', 'pb16', 'rounded')
            inner.classList.add('mx-auto', 'my-auto', 'col-10', 'px-0', 'pt0', 'pb0', 'bg-white', 'rounded-4')
            inner.style.borderRadius = null
            inner.style.borderWidth = null
            inner.style.borderColor = null

            const content = inner.querySelector('.s_features_grid_content')
            content.classList.add('mt-3', 'mt-sm-0', 'arguments,-1', 'px-5', 'px-sm-1', 'px-md-5', 'px-lg-1', 'px-xl-4', 'py-0', 'py-sm-1', 'py-md-4', 'py-lg-1', 'py-xl-3')

            const image = inner.querySelector('.s_features_grid_icon')
            image.classList.add('float-none', 'float-sm-start', 'rounded-4', 'rounded-end-sm-0', 'mt-3', 'mt-sm-0', 'mx-auto', 'mx-sm-0')
        })

        const button = grid.querySelector('a')
        button.classList.remove('rounded-circle')
        button.classList.add('fw-bold')
    }
}
