import { XrowStepButton } from './XrowStepButton'
import { XrowNavPills } from './XrowNavPills'
import { GoogleTagManager } from './XrowGoogleTagManager/src/XrowGoogleTagManager'

export class XrowTabForm {
    private readonly MODEL = 'crm.lead'
    private readonly steps = ['step-1', 'step-2', 'step-3']

    public run(): void {
        const forms = document.querySelectorAll('form[data-model_name="' + this.MODEL + '"]')
        forms.forEach((form: HTMLFormElement) => this.modifyForm(form))
    }

    /**
     * Use the provided tracker to track successful form submissions.
     * Since no 'submit' event is fired by Odoo, we need to track the specific Ajax request.
     * Currently Odoo use jQuery to send the form data. If in future they use a
     * different library, please update this method.
     */
    public trackSubmits(GTM: GoogleTagManager): void {
        (async() => {
            while (!window.hasOwnProperty('$')) {
                await new Promise(resolve => setTimeout(resolve, 100))
            }

            window.$(document).on('ajaxComplete', (event: JQuery.SubmitEvent, xhr: JQuery.jqXHR, options: JQuery.PlainObject) => {
                if (options.url !== ('/website/form/' + this.MODEL) || options.type !== 'POST') {
                    return
                }

                if (xhr.status !== 200) {
                    return
                }

                // Even with status 200 the submission might have failed
                const response = xhr.responseText
                try {
                    const result = JSON.parse(response);
                    if (result.error !== undefined) {
                        console.error(result.error)
                        return
                    }
                } catch (exception) {
                    console.error(exception)
                    return
                }

                GTM.callToAction(event.target.activeElement, 'leadform', 'Lead form submit')
            })
        })()
    }

    public trackFocus(GTM: GoogleTagManager): void {
        const forms = document.querySelectorAll('form[data-model_name="' + this.MODEL + '"]')
        forms.forEach((form: HTMLFormElement) => {
            form.addEventListener(
                'focus',
                (event: FocusEvent) => {
                    const target = <HTMLInputElement>event.target

                    if (!target.name) {
                        return
                    }

                    const label = target.labels.item(0).innerText.trim()
                    const placeholder = target.placeholder.trim()

                    let name = target.name
                    if (placeholder) {
                        name = placeholder
                    } else if (label) {
                        name = label
                    }

                    GTM.eventWithData(
                        'focus_forms',
                        {
                            'input_click': name,
                            'input_label': name,
                            'input_value': target.type == 'radio' ? label : '*'
                        },
                        `Focus on input with name '${name}'`
                    )
                },
                true
            )
        })
    }

    private modifyForm(form: HTMLFormElement): void {

        this.addStyles(form)

        this.configureSuccessMessage(form)

        const nav = new XrowNavPills(form)

        this.modifyRecaptcha(form)
        this.defineSteps(form)

        const button1 = new XrowStepButton(form, 'step-1', 'Weiter');
        const button2 = new XrowStepButton(form, 'step-2', 'Weiter');
        this.hideOtherSteps(form, 'step-1')

        button1.disable()
        button1.get().addEventListener('click', (event) => {
            this.showStep(form, 'step-2')
            this.hideOtherSteps(form, 'step-2')
            this.enablePill(form, 'step-1')
            this.enablePill(form, 'step-2')
        });

        const radiosInStep1 = form.querySelectorAll('.step-1 input[type="radio"]')
        radiosInStep1.forEach((input) => {
            input.addEventListener('click', (event) => {
                button1.enable()
            })
        })

        button2.disable()
        button2.get().addEventListener('click', (event) => {
            this.showStep(form, 'step-3')
            this.hideOtherSteps(form, 'step-3')
            this.enablePill(form, 'step-3')
        });

        const inputsInStep2 = form.querySelectorAll('.step-2 input, .step-2 textarea')
        inputsInStep2.forEach((input) => {
            input.addEventListener('keyup', (event) => {
                button2.enable()
                nav.enableStep('step-3')
                inputsInStep2.forEach((input: HTMLInputElement) => {
                    if (input.required && input.value.trim() == '') {
                        button2.disable()
                        nav.disableStep('step-3')
                    }
                })
            })
        })

        const submit = <HTMLButtonElement>form.querySelector('.s_website_form_send')
        submit.classList.add('disabled')

        const inputsInStep3 = form.querySelectorAll('.step-3 input, .step-3 textarea')
        inputsInStep3.forEach((input) => {
            input.addEventListener('keyup', (event) => {
                submit.classList.remove('disabled')
                inputsInStep3.forEach((input: HTMLInputElement) => {
                    if (input.required && input.value.trim() == '') {
                        submit.classList.add('disabled')
                    }
                })
            })
        })
    }

    /**
     * Add and remove classes to form containers to apply styling such as a background image.
     * The form must have a sibling element with class 's_text_highlight'.
     */
    private addStyles(form: HTMLFormElement) {
        const formRow = form.closest('.row')
        const formCol = form.closest('.col-lg-6')
        const sibling = formCol.previousElementSibling
        const siblingInner = sibling.querySelector('.s_text_highlight')

        if (siblingInner == null) {
            return
        }

        const formContainer = <HTMLElement>form.closest('.s_text_image')

        formRow.classList.remove('align-items-center')

        formCol.classList.add('bg-white', 'my-3', 'rounded-4', 'rounded-start-lg-0')

        sibling.classList.add('px-0')

        siblingInner.classList.remove('my-3')
        siblingInner.classList.add('rounded-4', 'rounded-end-lg-0', 'text-white')

        formContainer.style.backgroundImage = null;
        formContainer.classList.remove('pb0', 'pt0')
        formContainer.classList.add('form-background-leadform')
    }

    /**
     * Add classes to field containers to identify which step they belong to
     */
    private defineSteps(form: HTMLFormElement) {
        const fieldContainers = form.querySelectorAll('.s_website_form_field:not(.s_website_form_dnone), .s_website_form_submit')

        let index = 0
        for (const step of this.steps) {
            fieldContainers.item(index++).classList.add(step)
        }

        const lastStep = this.steps[this.steps.length - 1]
        for (; index < fieldContainers.length; index++) {
            fieldContainers.item(index).classList.add(lastStep)
        }
    }

    /**
     * If a success message exists in the HTML, then tell Odoo to use it instead
     * of redirecting (it seems this can't be configured by the editor, but the
     * code exists).
     * See https://github.com/odoo/odoo/blob/master/addons/website/static/src/snippets/s_website_form/000.js
     */
    private configureSuccessMessage(form: HTMLFormElement): void {
        const successMessage = form.parentNode.querySelector('.s_website_form_end_message')
        if (!successMessage) {
            console.warn("Cannot find form submission success message container with class 's_website_form_end_message, will redirect on successful form submission.'")

            return;
        }

        form.dataset.successMode = 'message'
        delete form.dataset.successPage
        successMessage.classList.add('d-none')
    }

    /**
     * Add class to recaptcha element to put it in step 3.
     */
    private modifyRecaptcha(form: HTMLFormElement): void {
        const recaptcha = form.querySelector('.s_website_form_recaptcha')
        if (!recaptcha) {
            return
        }

        recaptcha.classList.add('step-3')

        // Hide the label and legal terms
        const recaptchaLabel = <HTMLElement>recaptcha.querySelector('.s_website_form_label')
        recaptchaLabel.style.display = 'none'
        const recaptchaTerms = <HTMLElement>recaptcha.querySelector('.o_recaptcha_legal_terms')
        recaptchaTerms.style.display = 'none'
    }

    public enablePill(form: HTMLFormElement, step: string): void {
        const pill = form.querySelector('.nav-' + step)
        pill.addEventListener('click', (event) => {
            if (pill.classList.contains('active')) {
                return
            }

            const step = pill.getAttribute('data-show')
            this.showStep(form, step)
            this.hideOtherSteps(form, step)
        });
    }

    public showStep(form: HTMLFormElement, step: string): void {
        const fieldContainers = form.querySelectorAll('.' + step)
        fieldContainers.forEach((fieldContainer) => {
            fieldContainer.classList.remove('d-none')
        })
        const pill = form.querySelector('.nav-' + step);
        pill.classList.add('active')
    }

    public hideOtherSteps(form: HTMLFormElement, targetStep: string): void {
        const steps = this.steps.filter((step) => step !== targetStep)
        steps.forEach((step) => {this.hideStep(form, step)})
    }

    public hideStep(form: HTMLFormElement, step: string): void {
        const fieldContainers = form.querySelectorAll('.' + step)
        fieldContainers.forEach((fieldContainer) => {
            fieldContainer.classList.add('d-none')
        })
        const pill = form.querySelector('.nav-' + step);
        pill.classList.remove('active')
    }

    private findClosestFormGroup(container: HTMLElement, selector: string): Element {
        const selected = container.querySelector(selector)
        if (!selected) {
            throw `Cannot find element '${selector}', please check form elements in Odoo.`
        }

        const fieldContainer = selected.closest('.s_website_form_field')
        if (!fieldContainer) {
            throw `Uexpected DOM structure wrapping the element '${selector}', please check form structure in Odoo.`
        }

        return fieldContainer
    }
}
