import { getCookie } from 'typescript-cookie'
import { GoogleTagManager, gtag } from '../../XrowGoogleTagManager/src/XrowGoogleTagManager';

class XrowComponents {
  public static SeoOptimize(): void {
    const urlParams = new URLSearchParams(window.location.search);

    if (window.location.hostname === "xrow-gmbh2.odoo.com" ||
      urlParams.get('category') ||
      urlParams.get('unique')
    ) {
      var meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex';
      document.head.appendChild(meta);
    }

    if (document.querySelector("meta[property='og:url']")) {
      var href = document.querySelector("meta[property='og:url']").getAttribute("content");
      var link = document.createElement('link');
      link.rel = 'canonical';
      link.href = href;
      document.head.appendChild(link);
    }

    var elements = document.getElementsByClassName("o_footer_copyright");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  public static conversion(url: any): boolean {
    var callback = function () {
      if (typeof (url) != 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      'send_to': 'AW-1071814421/7uEBCKuDioUYEJWuiv8D',
      'transaction_id': '',
      'event_callback': callback
    });
    return false;
  }

  public static loadGTM(): void {
    // gtag('set', {
    //   'phone_conversion_number': '+49 (511) 473915400',
    //   'phone_conversion_callback': callback,
    //   'phone_conversion_ids': ['AW-1071814421/tsGtCL7J5vADEJWuiv8D']
    // });
    // gtag('config', 'AW-1071814421',{
    //   'transport_url': 'https://tracking.xrow.de',
    // });

    var callback = function (formatted_number: string, mobile_number: string) {
      console.log("Phone callback")
      // formatted_number: anzuzeigende Nummer, im selben Format wie
      // 'phone_conversion_number'.
      // (in this case, '1-800-123-4567')
      // mobile_number: zur Verwendung in einem Link formatierte Nummer
      // with tel:-URI ((in diesem Fall '+18001234567')
      var e = document.getElementById("phone") as HTMLAnchorElement
      e.href = "tel:" + mobile_number
      e.innerHTML = ""
      e.appendChild(document.createTextNode(formatted_number))
    };
  }
}

export { XrowComponents };
