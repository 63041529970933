import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { Translator, TranslationStrings } from '../../Translator';
import 'vanilla-cookieconsent';
import { XrowComponents } from '../../XrowComponents';
import { GoogleTagManager, gtag } from '../../XrowGoogleTagManager/src/XrowGoogleTagManager';

class CookieConsent {
    private applicationsByState: { [key: string]: any[] } = {}
    private ts: Translator
    private xliff: TranslationStrings

    constructor() {
        window.CookieConsentApi = window.initCookieConsent();
        this.ts = new Translator()

        if (this.getLanguageCode() == 'en') {
            this.xliff = this.ts.translate(require('./i18n.de.xlf'), true);
        } else {
            try {
                this.xliff = this.ts.translate(require('./i18n.' + this.getLanguageCode() + '.xlf'));
            } catch (error) {
                console.log(error)
                console.log("Using fallback en")
                this.xliff = this.ts.translate(require('./i18n.de.xlf'), true);
            }
        }
    }

    public static updateConsent(): void {
        if (window.CookieConsentApi == null) {
            console.log('Load consent API before tracking');
        }

        if (window.CookieConsentApi.allowedCategory('analytics')) {
            console.log('Analytics allowed');
            GoogleTagManager.consentGranted();
        } else {
            GoogleTagManager.consentDenied();
        }
    }

    public getLanguageCode(): string {
        if (document.documentElement.getAttribute('lang')) {
            return document.documentElement.getAttribute('lang').substring(0, 2);
        }
        else {
            return 'en'
        }
    }

    public getLanguageConfig(): any {
        var config: { [key: string]: any; } = {};
        config[this.getLanguageCode()] = {
            gui_options: {
                consent_modal: {
                    layout: 'box',               // box/cloud/bar
                    position: 'middle center',     // bottom/middle/top + left/right/center
                    transition: 'slide',           // zoom/slide
                    swap_buttons: false            // enable to invert buttons
                },
            },
            consent_modal: {
                title: this.xliff.title,
                description: this.xliff.intro + ' <button type="button" data-cc="c-settings" class="cc-link">' + this.xliff.title + '</button>',
                primary_btn: {
                    text: this.xliff.accept_all,
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: this.xliff.accept_necessary,
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: this.xliff.title,
                save_settings_btn: this.xliff.save_settings_btn,
                accept_all_btn: this.xliff.accept_all_btn,
                reject_all_btn: this.xliff.reject_all_btn,
                close_btn_label: this.xliff.close_btn_label,
                cookie_table_headers: [
                    { col1: this.xliff.table_name },
                    { col2: this.xliff.table_domain },
                    { col3: this.xliff.table_expiration },
                    { col4: this.xliff.table_description }
                ],
                blocks: [
                    {
                        title: this.xliff.blocks_0_title + '📢',
                        description: this.xliff.blocks_0_description,
                    }, {
                        title: this.xliff.blocks_1_title,
                        description: this.xliff.blocks_1_description,
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: this.xliff.blocks_2_title,
                        description: this.xliff.blocks_2_description,
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            }
                        ]
                    }, {
                        title: this.xliff.blocks_3_title,
                        description: this.xliff.blocks_3_description,
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }
                ]
            }
        };
        return config
    }

    public run(): any {
        // run plugin with your configuration
        window.CookieConsentApi.run({
            current_lang: this.getLanguageCode(),
            autoclear_cookies: true,                   // default: false
            page_scripts: true,                        // default: false

            // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
            // delay: 0,                               // default: 0
            // auto_language: '',                      // default: null; could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            hide_from_bots: true,
            // remove_cookie_tables: false             // default: false
            // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
            // cookie_expiration: 182,                 // default: 182 (days)
            // cookie_necessary_only_expiration: 182   // default: disabled
            // cookie_domain: 'xrow.de',       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'SameSite=None; Secure',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            // revision: 0,                            // default: 0

            onFirstAction: function (user_preferences, cookie) {
                console.log('callback triggered only once on the first accept/reject action');
                CookieConsent.updateConsent()
            },

            onAccept: function (cookie) {
                console.log('callback triggered on the first accept/reject action, and after each page load');
            },

            onChange: function (cookie, changed_categories) {
                console.log('callback triggered when user changes preferences after consent has already been given');

                CookieConsent.updateConsent()
            },

            languages: this.getLanguageConfig(),
        });

        window.CookieConsentApi.show();
        CookieConsent.updateConsent()

        // If not odoo backend
        if ( document.documentElement.hasAttribute('data-editable') === false ) {
            var links = document.querySelectorAll('a[href="#__consent"]') as NodeListOf<HTMLButtonElement>;
            if (links) {
                for (var i = 0; i < links.length; i++) {
                    links[i].onclick = (event: MouseEvent) => {
                        window.CookieConsentApi.showSettings(0.5); return false;
                    };
                }
            } else {
                console.log("no #__consent link on page");
            }
        }
    }
}

export { CookieConsent };
