export class XrowNavPills {
    private nav: HTMLElement;

    constructor(container: HTMLElement) {
        if (!container.querySelector('.form-steps')) {
            container.insertAdjacentHTML(
                'afterbegin',
                `<ul class="nav nav-pills justify-content-between form-steps">
                    <li class="nav-item">
                        <button type="button" class="nav-link rounded-circle nav-step-1 active" data-show="step-1">1</button>
                    </li>
                    <li class="nav-item">
                        <button type="button" class="nav-link rounded-circle nav-step-2" data-show="step-2">2</button>
                    </li>
                    <li class="nav-item">
                        <button type="button" class="nav-link rounded-circle nav-step-3" data-show="step-3">3</button>
                    </li>
                </ul>`
            )
        }

        this.nav = container.querySelector('.form-steps')
    }

    public disableStep(step: string): void {
        const button = <HTMLButtonElement>this.nav.querySelector(`.nav-${step}`)
        button.disabled = true;
    }

    public enableStep(step: string): void {
        const button = <HTMLButtonElement>this.nav.querySelector(`.nav-${step}`)
        button.disabled = false;
    }
}
